var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-section',{staticClass:"accent",attrs:{"id":"product-technology"}},[_c('base-section-headingTwo',{attrs:{"title":"Industries We Supply Specialty Chemicals","icon":"mdi-factory","outlined":""}}),_c('v-container',[_c('v-row',_vm._l((_vm.contents),function(ref){
var title = ref.title;
var src = ref.src;
var text = ref.text;
var n = ref.n;
return _c('v-col',{key:n,attrs:{"cols":"12","md":"3","sm":"12"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('base-img',{attrs:{"height":_vm.$vuetify.breakpoint.mdAndUp ? 400 : 200,"src":src,"color":"grey lighten-1","flat":"","tile":""}},[_c('v-overlay',{attrs:{"value":hover,"absolute":"","color":"secondary","opacity":".7"}},[_c('base-subheading',{staticClass:"text-uppercase mx-5 px-5",staticStyle:{"line-height":"100%"},attrs:{"title":title,"align":"center","mobileSize":"h6"}}),_c('base-body',{staticClass:"text-center mx-5 px-5",staticStyle:{"line-height":"120%"},attrs:{"text":text}})],1)],1)]}}],null,true)})],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }